import { useContext } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { Buffer, Column, Workbook, Worksheet } from 'exceljs';
import { BsDownload } from 'react-icons/bs';

import { Deal, DealStateEnum, DealTypeLabelMap, exportDealsQuery } from '../../../../gql/dealGql';
import {
  TitleRegistrationOptionLabels,
  getAdjustedRegistrationFee,
  getTotalTaxes,
} from '../../../../gql/financialInfoGql';

import { tableViewColumns } from '../ColumnComponents';

import { logger } from '../../../../libs/Logger';
import { FiltersContext } from '../../../../libs/contextLib';
import { formatMoney } from '../../../../libs/utils';
import { createDateISO } from '../../../../utils/dates';

const autoWidth = (worksheet: Worksheet, minWidth = 10) => {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;
    (column as Column).eachCell({ includeEmpty: true }, (cell) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minWidth,
        cell.value ? cell.value.toString().length : 0,
      );
    });
    // eslint-disable-next-line no-param-reassign
    column.width = maxColumnLength;
  });
};

const ExportButton = () => {
  const {
    filters: {
      titling: titlingFilters,
      global: { selectedPodId, sources, types },
    },
  } = useContext(FiltersContext);
  const [getTitlingDashboardDeals, { loading }] = useLazyQuery(exportDealsQuery, {
    fetchPolicy: 'network-only',
  });

  const saveByteArray = (reportName: string, buffer: Buffer) => {
    const blob = new Blob([buffer], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.download = reportName;
    link.click();
  };

  const downloadData = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { currentPage, itemsPerPage, field, direction, userChangedFilters, ...newFilters } = {
      ...titlingFilters,
    };
    const { data }: { data: { titlingDashboardDeals: { deals: Deal[] } } } =
      await getTitlingDashboardDeals({
        variables: {
          sources,
          types,
          states: newFilters.dealStates,
          pod_id: selectedPodId,
          page: -1,
          itemsPerPage,
          filters: newFilters,
          sorting: { field, direction },
        },
      });

    const newHeaders = [
      { header: 'Name', key: 'Name' },
      { header: 'Year', key: 'Year' },
      { header: 'Make', key: 'Make' },
      { header: 'Model', key: 'Model' },
      { header: 'Vin', key: 'Vin' },
      { header: 'Zipcode', key: 'Zipcode' },
      { header: 'State', key: 'State' },
      { header: 'Type', key: 'Type' },
      { header: 'Deal State', key: 'Deal State' },
      { header: 'Lienholder', key: 'Lienholder' },
      { header: 'Bank', key: 'Bank' },
      { header: 'Payoff', key: 'Payoff' },
      { header: 'Money Down', key: 'Money Down' },
      { header: 'Taxes', key: 'Taxes' },
      { header: 'Title', key: 'Title' },
      { header: 'Registration', key: 'Registration' },
      { header: 'Doc Fee', key: 'Doc Fee' },
      { header: 'Amount Financed', key: 'Amount Financed' },
      { header: 'Problem Deal', key: 'Problem Deal' },
      { header: 'Title Received Date', key: 'Title Received Date' },
      { header: 'Title Team', key: 'Title Team' },
      { header: 'Title Clerk', key: 'Title Clerk' },
      { header: 'Reg Assistant', key: 'Reg Assistant' },
      { header: 'Estimate Date', key: 'Estimate Date' },
      { header: 'Soft Close Date', key: 'Soft Close Date' },
      { header: 'Structuring Date', key: 'Structuring Date' },
      { header: 'Closing Date', key: 'Closing Date' },
      { header: 'Closed Date', key: 'Closed Date' },
      { header: 'Signatures Date', key: 'Signatures Date' },
      { header: 'Signed Date', key: 'Signed Date' },
      { header: 'Funded Date', key: 'Funded Date' },
      { header: 'Send Payoff Date', key: 'Send Payoff Date' },
      { header: 'Create Packet Date', key: 'Create Packet Date' },
      { header: 'Ready for Clerk Date', key: 'Ready for Clerk Date' },
      { header: 'Sent to Processor/DMV Date', key: 'Sent to Processor/DMV Date' },
      { header: 'Finalized Date', key: 'Finalized Date' },
    ];

    const rows = data.titlingDashboardDeals.deals.map((deal) => ({
      Year: deal.car.year,
      Make: deal.car.make,
      Model: deal.car.model,
      Vin: deal.car.vin,
      Zipcode: deal.customer.address?.zip,
      State: deal.customer.address?.state,
      Type: deal.type ? DealTypeLabelMap[deal.type] : '',
      Lienholder: deal.car.payoff.lienholder_name,
      Payoff: formatMoney(deal.car.payoff?.vehicle_payoff),
      'Money Down': formatMoney(deal.financial_info?.money_down),
      'Bank Fees': formatMoney(deal.financial_info?.bank_fees),
      Taxes: formatMoney(getTotalTaxes(deal.financial_info)),
      'Transaction Type': deal.financial_info.title_registration_option
        ? TitleRegistrationOptionLabels[deal.financial_info.title_registration_option]
        : TitleRegistrationOptionLabels.title_and_new_registration,
      Title: formatMoney(deal.financial_info.title_fee),
      Registration: formatMoney(getAdjustedRegistrationFee(deal.financial_info)),
      'Doc Fee': formatMoney(deal.financial_info.doc_fee),
      'Amount Financed': formatMoney(deal.financial_info.amount_financed),
      'Problem Deal': deal.has_problem,
      'Title Received Date': createDateISO(
        deal.deal_dates?.custom_dates?.[DealStateEnum.TitleReceived],
      ),
      'Title Team': deal.titling_pod?.name,
      'Title Clerk': deal.title_clerk?.name,
      'Reg Assistant': deal.title_clerk2?.name,
      'Estimate Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.Estimate]),
      'Soft Close Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.SoftClose]),
      'Structuring Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.Structuring]),
      'Closing Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.Closing]),
      'Closed Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.Closed]),
      'Signatures Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.SentForSignatures]),
      'Signed Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.Signed]),
      'Funded Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.Funded]),
      'Send Payoff Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.SendPayoff]),
      'Create Packet Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.WaitingForTitle]),
      'Ready for Clerk Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.TitleReceived]),
      'Sent to Processor/DMV Date': createDateISO(
        deal.deal_dates?.dates?.[DealStateEnum.SentToProcessor],
      ),
      'Finalized Date': createDateISO(deal.deal_dates?.dates?.[DealStateEnum.Finalized]),
      ...Object.entries(tableViewColumns).reduce(
        (obj, [header, { getValue }]) => ({ ...obj, [header]: getValue(deal) }),
        {},
      ),
    }));

    const workbook = new Workbook();
    const sheet = workbook.addWorksheet('Sheet1');
    sheet.columns = newHeaders;
    rows.forEach((row) => sheet.addRow(row));
    autoWidth(sheet);

    const now = new Date();
    const dateString = `${now.toLocaleDateString()}T${now.toLocaleTimeString([], {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })}`;
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => saveByteArray(`${dateString}-Title_Deals.xlsx`, buffer))
      // eslint-disable-next-line no-console
      .catch((err) => logger.error('ExportButton.tsx', '', null, err));
  };

  return (
    <Button
      size="lgWithIconLeft"
      leftIcon={<BsDownload />}
      isLoading={loading}
      loadingText="EXPORT"
      onClick={downloadData}
    >
      EXPORT
    </Button>
  );
};

export default ExportButton;
