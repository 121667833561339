import { useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import {
  Button,
  RadioGroup as ChakraRadioGroup,
  Radio,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FaUndo } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { getFullName } from '../../gql/customerGql';
import { Deal, DealStateEnum, DealStateLabelMap, isUneditable } from '../../gql/dealGql';
import { useDealUpdateStateMutation } from '../../gql/generated/graphql';

import UnwindButtonBootDealModal from '../BootDealModal/UnwindButtonBootDealModal';
import Modal from '../shared/Modal';

import { PermissionEnum } from '../../constants/permissions';
import { Can } from '../../libs/Can';

interface UnwindDealProps {
  deal: Deal;
  refetch?: () => Promise<ApolloQueryResult<Deal>>;
}

type UnwindDealStateType = DealStateEnum.Booted | DealStateEnum.Structuring | DealStateEnum.Signed;

const unwindLabels: Record<UnwindDealStateType, string> = {
  booted: 'Boot Deal',
  structuring: 'Send Back to Structuring',
  signed: 'Send Back to Signed',
};

const UnwindDeal = ({ deal, refetch }: UnwindDealProps) => {
  const {
    isOpen: isUnwindModalOpen,
    onOpen: onOpenUnwindModal,
    onClose: onCloseUnwindModal,
  } = useDisclosure();

  const {
    isOpen: isBootModalOpen,
    onOpen: onOpenBootModal,
    onClose: onCloseBootModal,
  } = useDisclosure();

  const [unwindState, setUnwindState] = useState<UnwindDealStateType>(DealStateEnum.Booted);

  const [updateDealState, { loading }] = useDealUpdateStateMutation();

  const onClickUnwindButton = async () => {
    if (!deal.id) {
      return;
    }

    if (unwindState === DealStateEnum.Booted) {
      onCloseUnwindModal();
      onOpenBootModal();
      return;
    }

    try {
      await updateDealState({
        variables: {
          id: deal.id,
          state: unwindState,
        },
      });
      toast.success(`Deal moved to ${DealStateLabelMap[unwindState]}.`);
      refetch?.();
    } catch (e) {
      toast.error('Failed to unwind deal.');
    } finally {
      onCloseUnwindModal();
    }
  };

  return (
    <Can I={PermissionEnum.BootDeal}>
      <Button
        size="lgWithIconLeft"
        leftIcon={<FaUndo />}
        variant="boot"
        onClick={onOpenUnwindModal}
        hidden={!isUneditable(deal.state)}
      >
        UNWIND DEAL
      </Button>

      <Modal
        title="Unwind Deal"
        isOpen={isUnwindModalOpen}
        onClose={onCloseUnwindModal}
        canDismiss={false}
        leftButtons={
          <Button variant="secondary" onClick={onCloseUnwindModal}>
            CLOSE
          </Button>
        }
        rightButtons={
          <Button
            isLoading={loading}
            loadingText={unwindLabels[unwindState].toUpperCase()}
            variant="warning"
            onClick={onClickUnwindButton}
          >
            {unwindLabels[unwindState].toUpperCase()}
          </Button>
        }
      >
        <Text color="black" align="left">
          You are about to unwind a deal for {getFullName(deal.customer)}. This cannot be undone, do
          you want to proceed?
        </Text>
        <ChakraRadioGroup
          value={unwindState}
          onChange={(nextValue) => setUnwindState(nextValue as UnwindDealStateType)}
          mt={4}
        >
          <VStack alignItems="start">
            <Radio value={DealStateEnum.Booted} m={0}>
              {unwindLabels.booted}
            </Radio>
            <Radio value={DealStateEnum.Structuring} m={0}>
              {unwindLabels.structuring}
            </Radio>
            <Radio value={DealStateEnum.Signed} m={0}>
              {unwindLabels.signed}
            </Radio>
          </VStack>
        </ChakraRadioGroup>
      </Modal>

      <UnwindButtonBootDealModal
        isOpen={isBootModalOpen}
        onClose={onCloseBootModal}
        refetch={refetch}
      />
    </Can>
  );
};

export default UnwindDeal;
