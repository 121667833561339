import { gql } from '@apollo/client/core';

import { ReserveStructure } from './reserveStructureGql';

export enum BankEnum {
  AFCU = 'America First Credit Union',
  FifthThird = 'Fifth Third Bank NA',
  Mechanics = 'Mechanics Bank',
  WellsFargo = 'Wells Fargo Auto',
  BankOfAmerica = 'Bank of America NA',
  CapitalOne = 'Capital One',
  AllyFinancial = 'Ally Financial',
  PNC = 'PNC Bank, NA',
  Westlake = 'Westlake Financial Services',
  TD = 'TD Bank N.A.',
  FirstInterstate = 'First Interstate Bank',
  ICCU = 'Idaho Central Credit Union',
  MountainAmerica = 'Mountain America FCU',
  LentegrityAutoFinance = 'Lentegrity Auto Finance',
  Lookout = 'Lookout Credit Union',
  Upgrade = 'Upgrade Inc.',
  Chase = 'JPMorgan Chase Bank, N.A.',
  Wings = 'Wings Credit Union',
  PrestigeFinancial = 'Prestige Financial Services',
  Santander = 'Santander Consumer USA',
}

export class BankMarkup {
  bank_id?: number;
  term_min?: number;
  term_max?: number;
  max_markup?: number;
}

export class Bank {
  active?: boolean;
  id?: number;
  name?: string;
  address?: string;
  phone?: string;
  r1_fsid?: string;
  reserve_structure?: ReserveStructure[];
  bank_markups?: BankMarkup[];
}

export const banksQuery = gql`
  query banks($state: String, $county: String, $active: Boolean, $selectedBank: String) {
    banks(state: $state, county: $county, active: $active, selectedBank: $selectedBank) {
      id
      name
      r1_fsid
      active
      reserve_structure {
        id
        bank_id
        rate_adj_min
        rate_adj_max
        flat_percentage
        term_min
        term_max
      }
      bank_markups {
        term_min
        term_max
        max_markup
      }
    }
  }
`;

export const bankInfoQuery = gql`
  query bankInfo($deal_id: ID!) {
    bankInfo(deal_id: $deal_id) {
      name
      address
      city
      state
      zip
      phone
      r1_fsid
    }
  }
`;
