import { PropsWithChildren } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import Loader from '../Loader';

type LEContainerProps = PropsWithChildren<
  BoxProps & {
    isLoading?: boolean;
  }
>;

const LeaseEndContainer = ({ isLoading, children, ...rest }: LEContainerProps) => {
  return (
    <Box>
      <Loader isLoading={isLoading ?? false} />
      <Box bg="snowyWhite" p="15px" overflow="hidden" {...rest}>
        {children}
      </Box>
    </Box>
  );
};

export default LeaseEndContainer;
