import { ReactNode } from 'react';

import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps } from '@chakra-ui/react';
import { FormikErrors, FormikValues } from 'formik';

import { Deal } from '../../gql/dealGql';

const getTooltip = (errors: FormikErrors<Deal> | (string | undefined)[]): ReactNode[] | null => {
  const errorArray = Array.isArray(errors) ? errors.filter(Boolean) : Object.values(errors);

  return errorArray?.length
    ? errorArray.flatMap((error) => {
        if (typeof error === 'object') {
          return getTooltip(error as FormikErrors<Deal>);
        }
        return <div key={error}>{error}</div>;
      })
    : null;
};

type TooltipProps<T extends FormikValues> = ChakraTooltipProps & {
  errors?: FormikErrors<T> | (string | undefined)[];
  label?: string | ReactNode[];
  children: ReactNode;
};

const Tooltip = <T extends FormikValues>({ errors, label, children, ...rest }: TooltipProps<T>) => {
  const mappedLabel = errors ? getTooltip(errors)?.filter(Boolean) : label;
  return (
    <ChakraTooltip
      label={mappedLabel}
      isDisabled={!mappedLabel || !mappedLabel.length}
      bgColor="errorsRed"
      shouldWrapChildren
      borderRadius={5}
      px={4}
      py={3}
      textAlign="center"
      hasArrow
      style={{
        display: 'flex',
      }}
      {...rest}
    >
      {children}
    </ChakraTooltip>
  );
};

export default Tooltip;
