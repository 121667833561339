import { useEffect } from 'react';

import { Flex, FlexProps, StyleProps } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Deal } from '../../../gql/dealGql';
import { ProcessorEnum } from '../../../gql/financialInfoGql';

import DatePicker from '../../shared/DatePicker';
import { DebouncedSave, customDateFields } from '../DealInfoBuyoutForm';

import InputRow from './InputRow';
import { QuickNotes } from './QuickNotes';

type CustomDateFieldsKey = keyof typeof customDateFields;
const customDateFieldsLabels: Partial<Record<CustomDateFieldsKey, string>> = {
  customSignedField: 'Signed Date',
  customPayoffSentField: 'Payoff Sent Date',
  customWetSigSentField: 'Wet Signature Sent Date',
  customWetSigReceivedField: 'Wet Signature Received Date',
  customTitleReceivedField: 'Title Received Date',
};
type TitlingSectionProps = FlexProps & {
  debouncedSave: DebouncedSave;
  saveCustomDate: (fieldName: string, value: string) => Promise<void>;
  saveProcessor: (value?: ProcessorEnum) => Promise<void>;
  defaultFontSize?: StyleProps['fontSize'];
};

export const TitlingSection = ({
  debouncedSave,
  saveCustomDate,
  saveProcessor,
  defaultFontSize = 'sm',
  ...rest
}: TitlingSectionProps) => {
  const { initialValues, values, touched } = useFormikContext<Deal>();

  useEffect(() => {
    if (
      initialValues.financial_info.processor === values.financial_info.processor &&
      !touched.financial_info?.processor
    ) {
      return;
    }

    saveProcessor(values.financial_info.processor);
  }, [values.financial_info.processor]);

  return (
    <Flex direction="column" {...rest}>
      {Object.entries(customDateFieldsLabels).map(([fieldKey, label]) => (
        <InputRow
          key={fieldKey}
          label={label}
          labelProps={{
            whiteSpace: 'normal',
          }}
        >
          <DatePicker
            name={customDateFields[fieldKey as CustomDateFieldsKey]}
            boxStyles={{
              w: '100%',
            }}
            inputProps={{
              fontSize: defaultFontSize,
            }}
            additionalHandleChange={(e) =>
              saveCustomDate(e.currentTarget.name, e.currentTarget.value)
            }
          />
        </InputRow>
      ))}
      <InputRow label="Quick Notes">
        <QuickNotes debouncedSave={debouncedSave} />
      </InputRow>
    </Flex>
  );
};
