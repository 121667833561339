import { useContext, useEffect } from 'react';

import { useFormikContext } from 'formik';

import { Bank } from '../gql/bankGql';
import { Deal, DealStateEnum } from '../gql/dealGql';
import { DealType, TitleRegistrationOption } from '../gql/generated/graphql';

import { OtherLienholder, OtherLienholderSlug } from '../constants/lienholders';
import { DealContext } from '../libs/DealContext';
import { getDefaultBankByDealType } from '../utils/financialInfos';

const OTHER_LENDER_NAME = 'Other';

interface UseSetValuesByDealTypeProps {
  banks?: Bank[];
}

export const useSetValuesByDealType = (props: UseSetValuesByDealTypeProps = {}) => {
  const { banks = [] } = props;

  const { deal, setUserChangedSomething } = useContext(DealContext);
  const { values, setValues, setFieldValue } = useFormikContext<Deal>();

  const isBeforeStructuring = [DealStateEnum.Estimate, DealStateEnum.SoftClose].includes(
    deal.state,
  );

  useEffect(() => {
    if (!isBeforeStructuring) {
      return;
    }

    const defaultBank = getDefaultBankByDealType(values.type);
    if (values.financial_info.bank !== defaultBank) {
      setUserChangedSomething(true);
      setFieldValue('financial_info.bank', defaultBank);
    }
  }, [values.type]);

  useEffect(() => {
    if (isBeforeStructuring || banks.length === 0) {
      return;
    }

    const selectedBankIsAvailable = banks.some((bank) => bank.name === values.financial_info.bank);
    if (selectedBankIsAvailable) {
      return;
    }

    const firstAvailableBank = banks[0].name;
    const defaultBank = getDefaultBankByDealType(values.type);
    const defaultBankIsAvailable = banks.some((bank) => bank.name === defaultBank);

    setUserChangedSomething(true);
    setFieldValue('financial_info.bank', defaultBankIsAvailable ? defaultBank : firstAvailableBank);
  }, [banks]);

  useEffect(() => {
    if (
      values.type === DealType.Refi &&
      (values.car.payoff.lienholder_slug !== OtherLienholderSlug ||
        values.car.payoff.lienholder_name !== OtherLienholder ||
        values.financial_info.title_registration_option !== TitleRegistrationOption.TitleOnly ||
        values.financial_info.plate_transfer !== false ||
        values.financial_info.buyer_not_lessee !== false ||
        values.financial_info.sales_tax_rate !== 0 ||
        values.financial_info.base_tax_amount !== 0 ||
        values.financial_info.warranty_tax_amount !== 0 ||
        values.financial_info.new_registration_fee !== 0 ||
        values.financial_info.registration_transfer_fee !== 0 ||
        values.financial_info.totalTaxAmount !== 0 ||
        values.customer.address.moved_states !== false)
    ) {
      const refiValues: Deal = {
        ...values,
        car: {
          ...values.car,
          payoff: {
            ...values.car.payoff,
            lienholder_slug: OtherLienholderSlug,
            lienholder_name: OtherLienholder,
          },
        },
        financial_info: {
          ...values.financial_info,
          title_registration_option: TitleRegistrationOption.TitleOnly,
          plate_transfer: false,
          buyer_not_lessee: false,
          sales_tax_rate: 0,
          base_tax_amount: 0,
          warranty_tax_amount: 0,
          new_registration_fee: 0,
          registration_transfer_fee: 0,
          totalTaxAmount: 0,
        },
        customer: {
          ...values.customer,
          address: {
            ...values.customer.address,
            moved_states: false,
          },
        },
      };
      setValues(refiValues, true);
    } else if (
      values.type !== DealType.Refi &&
      values.car.payoff.lender_name !== OTHER_LENDER_NAME
    ) {
      const notRefiValues: Deal = {
        ...values,
        car: {
          ...values.car,
          payoff: {
            ...values.car.payoff,
            lender_name: OTHER_LENDER_NAME,
          },
        },
      };
      setValues(notRefiValues, true);
    }
  }, [values.type]);
};
