import { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react';

import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { Bank, BankEnum } from '../../../gql/bankGql';
import { Deal } from '../../../gql/dealGql';
import { DaysToPaymentEnum, getFirstPaymentDateISO } from '../../../gql/financialInfoGql';

import NumberInput from '../../shared/NumberInput';
import Select from '../../shared/Select';
import { Option } from '../../shared/types';
import { daysToPaymentOptions } from '../constants';

import InputRow from './InputRow';

import { PermissionEnum } from '../../../constants/permissions';
import { Can } from '../../../libs/Can';
import { DealActionsEnum, DealContext } from '../../../libs/DealContext';
import { formatDate } from '../../../libs/utils';
import { getMaxBankMarkup } from '../../../utils/financialInfos';

interface BankAndPaymentsSectionProps extends FlexProps {
  isEditable: boolean;
  inputIsReadOnlyByPermissions: boolean;
  banks: Bank[];
  setUserChangedSomething: Dispatch<SetStateAction<boolean>>;
}

export const BankAndPaymentsSection = ({
  isEditable,
  inputIsReadOnlyByPermissions,
  banks,
  ...rest
}: BankAndPaymentsSectionProps) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Deal>();

  const { deal, dispatch, setUserChangedSomething } = useContext(DealContext);

  const bankOptions: Option[] = useMemo(
    () => banks.map((bank) => ({ label: bank.name ?? '', value: bank.name ?? '' })),
    [banks, values.customer.address.state],
  );

  const handleDaysToPaymentChange = (
    e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>,
  ) => {
    setUserChangedSomething(true);

    const daysToPayment = parseInt(e?.target?.value || '0', 10) || DaysToPaymentEnum.FortyFive;
    setFieldValue('financial_info.days_to_payment', daysToPayment);
    setFieldValue(
      'financial_info.first_payment_date',
      getFirstPaymentDateISO(daysToPayment, values.financial_info.bank),
    );
  };

  const handleBankChange = (e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    setUserChangedSomething(true);

    const bank = e?.target?.value as BankEnum;
    setFieldValue('financial_info.bank', bank);
    setFieldValue(
      'financial_info.first_payment_date',
      getFirstPaymentDateISO(values.financial_info?.days_to_payment, bank),
    );
    // Recalculate reserve using bank's calculation
    setFieldValue('financial_info.user_entered_reserve', null);
  };

  const handleBuyRateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserChangedSomething(true);

    const buyRate = parseFloat(e?.target?.value || '0');
    setFieldValue('financial_info.buy_rate', buyRate);
  };

  useEffect(() => {
    const maxMarkup = getMaxBankMarkup(
      banks,
      values.financial_info.bank,
      values.financial_info.term,
    );

    setFieldValue('financial_info.maxMarkup', maxMarkup);
  }, [banks, values.financial_info.bank, values.financial_info.term]);

  useEffect(() => {
    // Triggers maxMarkup validation on first render and from that point on (even when the other influencing fields change).
    setFieldTouched('financial_info.buy_rate', true);
  }, [values.financial_info.maxMarkup]);

  useEffect(() => {
    if (deal.financial_info.maxMarkup !== values.financial_info.maxMarkup) {
      // Keep in sync the context value with the formik value so it can be used in the auto-save logic
      // Needed because the context value because is overwritten by other dispatches
      dispatch({
        type: DealActionsEnum.DeepUpdateDeal,
        payload: {
          financial_info: {
            maxMarkup: values.financial_info.maxMarkup,
          },
        },
      });
    }
  }, [deal.financial_info.maxMarkup, values.financial_info.maxMarkup]);

  useEffect(() => {
    if (
      deal.financial_info?.first_payment_date &&
      deal.financial_info?.first_payment_date !== values.financial_info?.first_payment_date
    ) {
      setFieldValue('financial_info.first_payment_date', deal.financial_info.first_payment_date);
    }
  }, [deal.financial_info?.first_payment_date]);

  return (
    <Flex direction="column" {...rest}>
      <InputRow label="Bank">
        <Select
          name="financial_info.bank"
          size="sm"
          options={bankOptions}
          emptyOption={false}
          onChange={handleBankChange}
          isDisabled={inputIsReadOnlyByPermissions || !isEditable}
        />
      </InputRow>
      <InputRow label="Days To First Payment">
        <Select
          name="financial_info.days_to_payment"
          size="sm"
          options={daysToPaymentOptions}
          emptyOption={false}
          isDisabled={inputIsReadOnlyByPermissions || !isEditable}
          onChange={handleDaysToPaymentChange}
        />
      </InputRow>

      <InputRow label="First Payment Date">
        <Text variant="dealInfoBuyout">
          {formatDate(values.financial_info?.first_payment_date, 'utc')}
        </Text>
      </InputRow>

      <Can I={PermissionEnum.BuildDeal}>
        <InputRow label="Buy Rate">
          <NumberInput
            name="financial_info.buy_rate"
            isPercentage
            showThousandSeparator
            onChange={handleBuyRateChange}
            isDisabled={inputIsReadOnlyByPermissions || !isEditable}
          />
        </InputRow>
      </Can>
    </Flex>
  );
};
