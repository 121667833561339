import { useContext, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Button, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { MdArticle, MdKeyboardArrowDown } from 'react-icons/md';

import { BankEnum, bankInfoQuery } from '../../../gql/bankGql';
import { Customer } from '../../../gql/customerGql';
import { Bank, useTemplateInfoQuery } from '../../../gql/generated/graphql';
import { templateTextQuery } from '../../../gql/messageGql';

import { useUser } from '../../../hooks/useUser';
import { TextWidgetContext } from '../../../libs/contextLib';
import { capitalizeFirstLetter } from '../../../utils/text';

type TemplateSelectProps = {
  client: Customer;
  bank?: BankEnum;
};

type TemplateType = {
  id: number;
  description: string;
  text: string;
};

const TemplateSelect = ({ client, bank }: TemplateSelectProps) => {
  const { textDealId } = useContext(TextWidgetContext);
  const { setFieldValue } = useFormikContext();
  const { data: templateData } = useQuery<{ templateTexts: TemplateType[] }>(templateTextQuery);
  const templates: TemplateType[] = templateData?.templateTexts || [];
  const { data: bankData, refetch: bankRefetch } = useQuery<{ bankInfo: Bank }>(bankInfoQuery, {
    variables: { deal_id: textDealId },
    fetchPolicy: 'cache-first',
  });
  const { data: tempData } = useTemplateInfoQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: textDealId!, sources: [], types: [] },
  });
  const bankInfo: Bank | undefined = bankData?.bankInfo;
  const tempInfo = tempData?.deal;
  const user = useUser();

  useEffect(() => {
    bankRefetch();
  }, [bank]);

  const templateOptions = useMemo(() => {
    const fillText = (s: string) => {
      return s
        .replace('{USER}', user.name.split(' ')[0])
        .replace('{CLIENT}', capitalizeFirstLetter(client.first_name))
        .replace('{CUSTOMER_EMAIL}', client.email ? client.email : '')
        .replace('{BANK}', bankInfo?.name || 'BANK')
        .replace(
          '{BANK_ADDRESS}',
          bankInfo
            ? `${bankInfo.phone}, ${bankInfo.address}, ${bankInfo.city}, ${bankInfo.state}, ${bankInfo.zip}`
            : 'BANK INFO',
        )
        .replace(
          '{LIENHOLDER}',
          tempInfo?.car?.payoff?.lienholder_name ? tempInfo.car.payoff.lienholder_name : '',
        )
        .replace('{REP_PHONE}', user.phone_number ? user.phone_number : '')
        .replace(
          '{DOWN_PAYMENT}',
          tempInfo?.financial_info?.money_down
            ? tempInfo.financial_info.money_down.toString()
            : '0',
        )
        .replace('{YEAR}', tempInfo?.car?.year ?? '')
        .replace('{MAKE}', tempInfo?.car?.make ?? '')
        .replace('{MODEL}', tempInfo?.car?.model ?? '');
    };

    return templates.map((template) => (
      <Box
        p={2}
        _hover={{ bgColor: 'gray.200', cursor: 'pointer' }}
        key={template.id}
        onClick={() => {
          setFieldValue('text_message', fillText(template.text));
        }}
      >
        {template.description}
      </Box>
    ));
  }, [
    client.first_name,
    client.email,
    templates,
    bankInfo?.name,
    bankInfo?.phone,
    bankInfo?.address,
    bankInfo?.city,
    bankInfo?.state,
    bankInfo?.zip,
    user.name,
    user.phone_number,
    tempInfo?.car?.payoff?.lienholder_name,
    tempInfo?.financial_info?.money_down,
  ]);

  return (
    <Popover placement="left-start">
      <PopoverTrigger>
        <Button variant="primary" size="sm">
          <MdArticle size={20} color="white" />
          <MdKeyboardArrowDown size={15} color="white" />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Box bgColor="white" boxShadow="md" pt={2} pb={2} overflowY="scroll" h="200px">
          {templateOptions}
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default TemplateSelect;
