import { Bank } from '../gql/bankGql';
import { Deal, isUneditable } from '../gql/dealGql';
import { useBanksQuery } from '../gql/generated/graphql';

interface UseBanksProps {
  onlyActive?: boolean;
  deal?: Deal;
}

export const useBanks = (props: UseBanksProps = {}) => {
  const { onlyActive = false, deal } = props;

  const { data, refetch } = useBanksQuery({
    variables: {
      state: deal?.customer?.address?.state,
      county: deal?.customer?.address?.county,
      active: onlyActive,
      selectedBank: isUneditable(deal?.state) ? deal?.financial_info?.bank : undefined,
    },
  });

  if (!data?.banks?.length) {
    return { banks: [], refetch };
  }

  return { banks: data.banks as Bank[], refetch };
};
