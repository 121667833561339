import { Dispatch, ReactNode, SetStateAction } from 'react';

import {
  Box,
  Table as ChakraTable,
  Flex,
  Icon,
  StyleProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import { Maybe, SortDirection } from '../../../gql/generated/graphql';

import { TableColumns } from './types';

import { PaginationContextType } from '../../../hooks/usePagination';
import { isNullOrUndefined } from '../../../utils';
import Loader from '../../Loader';
import Pagination from '../../Pagination';

type HeaderColumnProps = {
  header: string;
  sortingKey?: string;
  sortProps?: {
    sortColumn: string;
    setSortColumn: Dispatch<SetStateAction<string>>;
    sortDirection: SortDirection;
    setSortDirection: Dispatch<SetStateAction<SortDirection>>;
  };
  styleProps?: StyleProps;
};
const HeaderColumn = ({ header, sortingKey, sortProps, styleProps }: HeaderColumnProps) => {
  const { sortColumn, setSortColumn, sortDirection, setSortDirection } = sortProps ?? {};
  const isSortable =
    !!sortingKey && !!sortColumn && !!setSortColumn && !!sortDirection && !!setSortDirection;

  const isSortColumn = sortColumn === sortingKey;
  const isSortAscending = sortDirection === 'asc';

  return (
    <Th
      color="white"
      cursor={isSortable ? 'pointer' : 'default'}
      {...styleProps}
      onClick={() => {
        if (isSortable) {
          // Toggle sort direction.
          if (isSortColumn) {
            setSortDirection(isSortAscending ? SortDirection.Desc : SortDirection.Asc);
          } else {
            setSortColumn(sortingKey);
            setSortDirection(SortDirection.Asc);
          }
        }
      }}
    >
      {header}
      {isSortColumn && isSortable ? (
        <Icon as={isSortAscending ? BiChevronDown : BiChevronUp} marginLeft={1} />
      ) : null}
    </Th>
  );
};

// TODO: Teams and StructuringFollowUpTable should use this component. Some others probably too.
type LETableProps<T> = {
  columns: TableColumns<T>;
  rowKeyPrefix: string;
  rowKeyProp: keyof T;
  items?: Maybe<T>[];
  isLoading?: boolean;
  handleEdit?: (item: T) => void;
  handleDelete?: (item: T) => void;
  sortProps?: {
    sortColumn: string;
    setSortColumn: Dispatch<SetStateAction<string>>;
    sortDirection: SortDirection;
    setSortDirection: Dispatch<SetStateAction<SortDirection>>;
  };
  paginationProps?: {
    paginationContext: PaginationContextType;
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    itemsPerPage: number;
    setItemsPerPage: Dispatch<SetStateAction<number>>;
  };
  children?: ReactNode;
  childrenContainerProps?: StyleProps;
};

const LETable = <T,>({
  columns,
  rowKeyPrefix,
  rowKeyProp,
  items,
  isLoading = false,
  sortProps,
  paginationProps,
  handleEdit,
  handleDelete,
  children,
  childrenContainerProps,
}: LETableProps<T>) => {
  const { paginationContext, currentPage, setCurrentPage, itemsPerPage, setItemsPerPage } =
    paginationProps ?? {};
  const isPaginable =
    !!paginationContext &&
    !isNullOrUndefined(currentPage) &&
    !!setCurrentPage &&
    !isNullOrUndefined(itemsPerPage) &&
    !!setItemsPerPage;

  return (
    <>
      <Flex
        h={16}
        w="100%"
        bgColor="oxfordBlue"
        borderRadius="10px 10px 0 0"
        alignItems="center"
        justifyContent="flex-end"
        px={8}
        {...childrenContainerProps}
      >
        {children}
      </Flex>
      <Box overflowX="auto">
        <ChakraTable overflowX="auto">
          <Thead cursor="pointer" color="white" bgColor="queenBlue" w="100%" px={6} height="55px">
            {columns.map(({ header, sortingKey, styleProps }) => (
              <HeaderColumn
                key={header}
                header={header}
                sortingKey={sortingKey}
                sortProps={sortProps}
                styleProps={styleProps}
              />
            ))}
          </Thead>
          <Tbody>
            {items?.map((item) => {
              if (!item) {
                return null;
              }

              return (
                <Tr
                  key={`${rowKeyPrefix}-${item[rowKeyProp]}`}
                  height="55px"
                  borderBottom="2px"
                  borderX="1px"
                  borderColor="backgroundGray"
                  _hover={{ bgColor: 'hoverGray' }}
                  bgColor="white"
                >
                  {columns.map(({ header, getComponent, styleProps }) => (
                    <Td key={header} alignContent="start" {...styleProps}>
                      {getComponent({ item, handleEdit, handleDelete })}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
      </Box>
      {isPaginable ? (
        <Box mb="150px">
          <Pagination
            filters={{
              currentPage,
              itemsPerPage,
            }}
            parentSetCurrentPage={setCurrentPage}
            parentSetItemsPerPage={setItemsPerPage}
            numItemsShown={items?.length ?? 0}
            paginationContext={paginationContext}
            itemsPerPageOptions={[50, 100]}
            borderTop="1px solid"
            borderColor="lightGray"
          />
        </Box>
      ) : null}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default LETable;
