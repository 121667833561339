import { useContext } from 'react';

import { Checkbox, HStack, Input, Select, Stack, Text, VStack } from '@chakra-ui/react';

import { DealStateEnum, DealStateLabelMap } from '../../gql/dealGql';
import { DealType } from '../../gql/generated/graphql';

import CompletedAcquisitionDealsTable from '../../components/CompletedDealsTables/CompletedAcquisitionsTable';
import CompletedDealsTable from '../../components/CompletedDealsTables/CompletedDealsTable';
import LeaseEndContainer from '../../components/Container/LEContainer';
import ButtonsRow from '../../components/Dashboards/ButtonsRow';
import UserAutocomplete from '../../components/shared/UserAutocomplete';

import { PermissionEnum } from '../../constants/permissions';
import { FiltersActionKind } from '../../globalFiltersUtils';
import { Can } from '../../libs/Can';
import { FiltersContext } from '../../libs/contextLib';
import { states } from '../../libs/states';
import { formatDateISO } from '../../libs/utils';

const CompletedDealsPage = () => {
  const {
    filters: {
      completed: { endDate, startDate, dealState, addressState, user },
      global: { showOnlyUnread, showAssignedToMe, types },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  return (
    <>
      <ButtonsRow />
      <LeaseEndContainer>
        <HStack gap={4} mb={4} mx={10}>
          {/* Start Date */}
          <VStack align="start">
            <Text>Start Date</Text>
            <Input
              type="date"
              bgColor="white"
              defaultValue={formatDateISO(startDate)}
              onChange={(e) => dispatchFilters({ type: 'setStartDate', payload: e.target.value })}
            />
          </VStack>
          {/* End Date */}
          <VStack align="start">
            <Text>End Date</Text>
            <Input
              type="date"
              bgColor="white"
              defaultValue={formatDateISO(endDate)}
              onChange={(e) => dispatchFilters({ type: 'setEndDate', payload: e.target.value })}
            />
          </VStack>

          {/* Deal State Filter */}
          <VStack spacing="1" mt={4} align="start">
            <Text>Deal State</Text>
            <Select
              w="270px"
              bgColor="white"
              name="state"
              onChange={(e) => dispatchFilters({ type: 'setDealState', payload: e.target.value })}
            >
              <option value="all">ALL</option>
              {Object.values(DealStateEnum).map((stateValue) => (
                <option key={stateValue} value={stateValue}>
                  {DealStateLabelMap[stateValue as keyof typeof DealStateLabelMap]}
                </option>
              ))}
            </Select>
          </VStack>
          {/* US State Filter */}
          <VStack spacing="1" mt={4} align="start">
            <Text>State</Text>
            <Select
              w="80px"
              bgColor="white"
              name="state"
              onChange={(e) =>
                dispatchFilters({ type: 'setAddressState', payload: e.target.value })
              }
            >
              <option value="all">ALL</option>
              {Object.keys(states).map((stateKey) => (
                <option key={stateKey} value={states[stateKey as keyof typeof states]}>
                  {states[stateKey as keyof typeof states]}
                </option>
              ))}
            </Select>
          </VStack>
          {/* User Filter */}
          <Can I={PermissionEnum.SeeUsers}>
            <VStack spacing="1" mt={4} align="start">
              <Text>User</Text>
              <UserAutocomplete
                includeEmptyOption
                emptyOptionLabel="ALL"
                user={user}
                w="270px"
                setUser={(selectedUser) =>
                  dispatchFilters({ type: 'setUser', payload: selectedUser })
                }
              />
            </VStack>
          </Can>
          <VStack spacing={0} align="start">
            <Checkbox
              onChange={(e) =>
                dispatchFilters({
                  type: FiltersActionKind.SET_SHOW_ONLY_UNREAD,
                  payload: e.target.checked,
                })
              }
              isChecked={showOnlyUnread}
            >
              Unread Messages
            </Checkbox>
            <Can I={PermissionEnum.SeeAssignedToMeFilter}>
              <Checkbox
                isChecked={showAssignedToMe}
                onChange={(e) =>
                  dispatchFilters({
                    type: FiltersActionKind.SET_SHOW_ASSIGNED_TO_ME,
                    payload: e.target.checked,
                  })
                }
              >
                Assigned to Me
              </Checkbox>
            </Can>
          </VStack>
        </HStack>
        <Stack spacing={8}>
          {(types.includes(DealType.Buyout) || types.includes(DealType.Refi) || !types.length) && (
            <CompletedDealsTable
              startDate={startDate}
              endDate={endDate}
              user={user}
              dealState={dealState}
              addressState={addressState}
              notifications={showOnlyUnread}
            />
          )}

          {(types.includes(DealType.Acquisition) || !types.length) && (
            <CompletedAcquisitionDealsTable
              startDate={startDate}
              endDate={endDate}
              user={user}
              dealState={dealState}
              addressState={addressState}
              notifications={showOnlyUnread}
            />
          )}
        </Stack>
      </LeaseEndContainer>
    </>
  );
};

export default CompletedDealsPage;
