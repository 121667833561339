import { ChangeEventHandler } from 'react';

import { Box, BoxProps, Checkbox } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { nameSuffixOptions } from '../../../gql/customerGql';
import { Deal } from '../../../gql/dealGql';

import CardSubHeaderV2 from '../../shared/Card/components/CardSubHeaderV2';
import { GridFormColumn, GridFormRow } from '../../shared/GridForm';
import Input from '../../shared/Input';
import Select from '../../shared/Select';
import Switch from '../../shared/Switch';

import { phoneNumberMask } from '../../../constants/masks';

interface Props extends BoxProps {
  name: keyof Pick<Deal, 'customer' | 'cobuyer'>;
  isUneditable: boolean;
  hideBuyerNotLessee?: boolean;
  customHandleChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  inScheduleFollowUpForm?: boolean;
}

export const PersonalInformationForm = ({
  name,
  isUneditable,
  customHandleChange,
  hideBuyerNotLessee = false,
  inScheduleFollowUpForm = false,
  ...rest
}: Props) => {
  const isCobuyer = name === 'cobuyer';

  const { values, handleChange: formikHandleChange, handleBlur } = useFormikContext<Deal>();
  const handleChange = customHandleChange ?? formikHandleChange;

  const noEmailValue = values[name]?.no_email;
  const nameSuffixValue = values[name]?.name_suffix ?? '';

  return (
    <Box mb={5} {...rest}>
      <CardSubHeaderV2 title="Personal Information" />
      <GridFormColumn>
        <GridFormRow>
          <Input
            label="First Name"
            name={`${name}.first_name`}
            onChange={handleChange}
            isDisabled={isUneditable}
          />
          {!inScheduleFollowUpForm ? (
            <Input
              label="Middle Name"
              name={`${name}.middle_name`}
              onChange={handleChange}
              isDisabled={isUneditable}
            />
          ) : null}
          <Input
            label="Last Name"
            name={`${name}.last_name`}
            onChange={handleChange}
            isDisabled={isUneditable}
          />
          {!inScheduleFollowUpForm ? (
            <Select
              label="Suffix"
              name={`${name}.name_suffix`}
              options={nameSuffixOptions}
              onChange={handleChange}
              value={nameSuffixValue}
              isDisabled={isUneditable}
            />
          ) : null}
        </GridFormRow>

        <GridFormRow minChildWidth={180}>
          <Input
            label="Mobile Phone"
            name={`${name}.phone_number`}
            onChange={handleChange}
            mask={phoneNumberMask}
            isDisabled={isUneditable}
            copyButton
          />
          {!inScheduleFollowUpForm ? (
            <Input
              label="Home Phone"
              name={`${name}.home_phone_number`}
              onChange={handleChange}
              isDisabled={isUneditable}
              mask={phoneNumberMask}
              copyButton
            />
          ) : null}
          <Input
            label="Email"
            name={`${name}.email`}
            onChange={handleChange}
            isDisabled={isUneditable}
            floatingInput={
              <Checkbox
                size="sm"
                name={`${name}.no_email`}
                checked={noEmailValue}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                No email
              </Checkbox>
            }
          />
          {!inScheduleFollowUpForm ? <Box /> : null}
        </GridFormRow>

        {hideBuyerNotLessee || isCobuyer ? null : (
          <Switch
            pt={5}
            label="Buyer or Co-Buyer is different than original lessee"
            name="financial_info.buyer_not_lessee"
            customHandleChange={handleChange}
            isDisabled={isUneditable}
          />
        )}
      </GridFormColumn>
    </Box>
  );
};
