import { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Flex, FlexProps, StyleProps, Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { getIn, useFormikContext } from 'formik';
import { DebouncedFunc } from 'lodash';
import { toast } from 'react-toastify';

import { Bank } from '../../gql/bankGql';
import {
  Deal,
  DealStateEnum,
  dealInfoUpsert,
  defaultToTitleInfo,
  isTitling,
} from '../../gql/dealGql';
import { BanksQuery, DealType, Maybe, TtJurisdiction } from '../../gql/generated/graphql';

import Autosaving from '../CreditApplication/components/Autosaving';
import CardHeaderV2 from '../shared/Card/components/CardHeaderV2';
import { SwitchesSection } from './components/SwitchesSection';

import { DealStructurePanel } from './DealStructurePanel';
import { TitleInfoPanel } from './TitleInfoPanel';
import { validationSchema } from './validationSchema';

import useDebounce from '../../hooks/useDebounce';
import { useSetValuesByDealType } from '../../hooks/useSetValuesByDealType';
import { DealActionsEnum, DealContext } from '../../libs/DealContext';
import { logger } from '../../libs/Logger';
import { removeFailedValidation } from '../../libs/utils';
import DealProblem from '../../pages/DealDetail/DealProblem';
import DealStatesModal from '../../pages/DealDetail/DealStatesModal';

export const sectionsContainerProps: FlexProps = {
  gap: 8,
  p: 4,
  direction: { base: 'column', xl: 'row' },
};
export const sectionWidth: StyleProps['w'] = { base: '100%', xl: '50%' };
export const dividerProps: FlexProps = {
  borderColor: 'gray.100',
  borderWidth: '1px',
};

const customSentToProcessorField = `deal_dates.custom_dates.${DealStateEnum.SentToProcessor}`;
const customSignedField = `deal_dates.custom_dates.${DealStateEnum.Signed}`;
// `WaitingForTitle` means that the payoff has been sent because the previous state is `SendPayoff`.
const customPayoffSentField = `deal_dates.custom_dates.${DealStateEnum.WaitingForTitle}`;
const customTitleReceivedField = `deal_dates.custom_dates.${DealStateEnum.TitleReceived}`;
const customWetSigSentField = 'deal_dates.custom_dates.wet_sig_sent_date';
const customWetSigReceivedField = 'deal_dates.custom_dates.wet_sig_received_date';

const processorField = 'financial_info.processor';
const baseTaxAmountField = 'financial_info.base_tax_amount';
const newBaseTaxAmountField = 'financial_info.newBaseTaxAmount';
const newRegistrationFee = 'financial_info.new_registration_fee';
const registrationTransferFee = 'financial_info.registration_transfer_fee';
const quickNotes = 'financial_info.quick_notes';
export const customDateFields = {
  customSentToProcessorField,
  customSignedField,
  customPayoffSentField,
  customTitleReceivedField,
  customWetSigSentField,
  customWetSigReceivedField,
};

const manuallySaveFields = [
  ...Object.values(customDateFields),
  processorField,
  baseTaxAmountField,
  newBaseTaxAmountField,
  newRegistrationFee,
  registrationTransferFee,
  quickNotes,
];

const maxMarkupField = 'financial_info.maxMarkup';
const tempUserEnteredReserveField = 'financial_info.tempUserEnteredReserve';
const doubleTaxAppliedField = 'car.payoff.doubleTaxApplied';
const previousTotalPayoffField = 'car.payoff.previousTotalPayoff';
const totalPayoffField = 'car.payoff.totalPayoff';
const frontendPayoffFields = [doubleTaxAppliedField, previousTotalPayoffField, totalPayoffField];
const frontendOnlyFields = [maxMarkupField, tempUserEnteredReserveField, ...frontendPayoffFields];

const bankFeesField = 'financial_info.bank_fees';
const userEnteredReserveField = 'financial_info.user_entered_reserve';
const cardPaymentAmountLimitField = 'financial_info.credit_card_payment_amount_limit';
const forceSaveFields = [bankFeesField, userEnteredReserveField, cardPaymentAmountLimitField];

export const isCustomDateField = (fieldName: string) =>
  Object.values(customDateFields).includes(fieldName);

type DebouncedSaveArgs = {
  newValues?: Deal;
  forceSave?: boolean;
};
export type DebouncedSave = DebouncedFunc<(args?: DebouncedSaveArgs) => Promise<void>>;

type DealInfoBuyoutFormProps = {
  banks: Bank[];
  banksRefetch: () => Promise<ApolloQueryResult<BanksQuery>>;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  isEditable: boolean;
  jurisdiction: TtJurisdiction;
};

export const DealInfoBuyoutForm = ({
  banks,
  banksRefetch,
  isEditing,
  setIsEditing,
  jurisdiction,
  isEditable,
}: DealInfoBuyoutFormProps) => {
  const { deal, dispatch, userChangedSomething, setUserChangedSomething } = useContext(DealContext);
  const { values, handleChange } = useFormikContext<Deal>();

  const [autosaving, setAutosaving] = useState<boolean>(false);
  const [saveTime, setSaveTime] = useState<string>('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultToTitleInfo(deal.state) ? 1 : 0);

  const [upsertDealInfo] = useMutation<{ dealInfoUpsert: Maybe<Deal> }>(dealInfoUpsert);

  const dealIsInTitlingState = isTitling(deal.state);

  // `useEffect` that sets the default values when the deal type changes.
  useSetValuesByDealType({ banks });

  const autosaveTimer = () => {
    setSaveTime(new Date().toLocaleTimeString('en-US'));
    setAutosaving(true);
    setTimeout(() => setAutosaving(false), 900);
  };

  const debouncedSave = useDebounce(
    async ({ newValues, forceSave = false }: DebouncedSaveArgs = {}) => {
      if (!isEditable && !forceSave) {
        return;
      }

      // In some cases the `values` in the Formik state are not updated yet.
      const valuesToSave = newValues ?? values;
      const valuesToSaveWithoutFailures = removeFailedValidation(valuesToSave, validationSchema);

      autosaveTimer();
      try {
        const { data } = await upsertDealInfo({
          variables: {
            financialInfo: valuesToSaveWithoutFailures.financial_info,
            car: valuesToSaveWithoutFailures.car,
            customer: valuesToSaveWithoutFailures.customer,
          },
        });

        if (!data?.dealInfoUpsert) {
          toast.error('Failed to update Deal Info');
          return;
        }
        dispatch({ type: DealActionsEnum.UpdateDeal, payload: data.dealInfoUpsert });
      } catch (error) {
        toast.error('Failed to update Deal Info');
        logger.error('DealInfoBuyoutForm.tsx', 'Failed to update Deal Info', null, error);
      }
    },
  );

  const customHandleChange = async (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | undefined,
  ) => {
    setUserChangedSomething(true);
    handleChange(e);
  };

  // We can't call `debouncedSave` on the `customHandleChange` because values are not updated yet.
  useEffect(() => {
    // `setFieldTouched` makes the form dirty so we can't use `dirty` it here.
    // If we can remove those and trigger initial validations in a different way, `customHandleChange` can be removed.
    if (!userChangedSomething) {
      return;
    }

    const someManuallySavedFieldChanged = manuallySaveFields.reduce((isChanged, fieldName) => {
      if (isCustomDateField(fieldName)) {
        const dealDate = getIn(deal, fieldName);
        const dealISODate = dealDate ? new Date(dealDate).toISOString() : null;

        const valuesDate = getIn(values, fieldName);
        const valuesISODate = valuesDate ? new Date(valuesDate).toISOString() : null;

        return isChanged || dealISODate !== valuesISODate;
      }

      return isChanged || getIn(deal, fieldName) !== getIn(values, fieldName);
    }, false);
    const someFrontendOnlyFieldChanged = frontendOnlyFields.reduce(
      (isChanged, fieldName) => isChanged || getIn(deal, fieldName) !== getIn(values, fieldName),
      false,
    );
    const someForceSaveFieldChanged = forceSaveFields.reduce(
      (isChanged, fieldName) => isChanged || getIn(deal, fieldName) !== getIn(values, fieldName),
      false,
    );
    if (
      (someManuallySavedFieldChanged || someFrontendOnlyFieldChanged) &&
      !someForceSaveFieldChanged
    ) {
      return;
    }

    debouncedSave({ newValues: values, forceSave: someForceSaveFieldChanged });
  }, [values]);

  return (
    <>
      <CardHeaderV2 title="Deal Details" showPodColor pod={deal.pod} variant="rounded">
        <Autosaving autosaving={autosaving} saveTime={saveTime} />
        <DealStatesModal />
        <DealProblem deal={deal} />
      </CardHeaderV2>
      <Tabs
        index={selectedTabIndex}
        onChange={(index) => setSelectedTabIndex(index)}
        variant="enclosed"
      >
        <TabList bgColor="queenBlue" pt={1}>
          <Tab
            bgColor={selectedTabIndex === 0 ? 'white' : 'gray.200'}
            borderRadius="8px 8px 0 0"
            fontWeight="bold"
            ml={1}
            px={8}
          >
            Deal Structure
          </Tab>
          {dealIsInTitlingState ? (
            <Tab
              bgColor={selectedTabIndex === 1 ? 'white' : 'gray.200'}
              borderRadius="8px 8px 0 0"
              fontWeight="bold"
              ml={1}
              px={8}
            >
              Title Info
            </Tab>
          ) : null}
        </TabList>
        <TabPanels>
          <DealStructurePanel
            debouncedSave={debouncedSave}
            jurisdiction={jurisdiction}
            banks={banks}
            banksRefetch={banksRefetch}
            isEditable={isEditable}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            customHandleChange={customHandleChange}
          />

          {dealIsInTitlingState ? (
            <TitleInfoPanel
              debouncedSave={debouncedSave}
              autosaveTimer={autosaveTimer}
              customHandleChange={customHandleChange}
            />
          ) : null}
        </TabPanels>
      </Tabs>

      {deal.type !== DealType.Refi ? (
        <>
          <Flex direction="row" {...dividerProps} />
          <Flex {...sectionsContainerProps}>
            <Flex direction="column" w={sectionWidth}>
              <SwitchesSection
                isEditable={isEditable}
                jurisdiction={jurisdiction}
                customHandleChange={customHandleChange}
              />
            </Flex>
          </Flex>
        </>
      ) : null}

      <Flex direction="row" {...dividerProps} />
    </>
  );
};
