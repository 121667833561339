// eslint-disable-no-non-null-assertion
import { useContext, useMemo, useState } from 'react';

import { Box, Flex, FormLabel, Heading, IconButton, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { FormikHelpers } from 'formik';
import { GoTriangleDown } from 'react-icons/go';
import { MdAddCircle } from 'react-icons/md';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { useNoteCreateMutation } from '../../gql/generated/graphql';
import {
  GroupedNoteTypeDisplay,
  GroupedNoteTypeEnum,
  GroupedNoteTypeFilter,
  Note,
  NoteTypeEnum,
} from '../../gql/noteGql';

import { Actions } from './Actions';
import { NewNote } from './NewNote';
import { NewNoteWidget } from './NewNoteWidget';
import UpdateNoteModal from './UpdateNoteModal';

import { useUser } from '../../hooks/useUser';
import { NotesContext } from '../../libs/contextLib';
import { formatDateWithTime, getTimezoneStr } from '../../libs/utils';

export interface ModalDetails {
  title: string;
  clickText: string;
  handleClick: (text?: string) => void;
  type: string;
  noteText?: string;
  loading: boolean;
}

interface CommunicationHistoryProps {
  canManagePins: boolean;
  canEditNote: boolean;
  dealId: number;
}

const sortOptions = [
  { label: 'Date (Newest)', value: 'desc' },
  { label: 'Date (Oldest)', value: 'asc' },
] as const;

const filterOptions = Object.values(GroupedNoteTypeEnum).map((value) => ({
  label: GroupedNoteTypeDisplay[value as keyof typeof GroupedNoteTypeDisplay],
  value,
}));

const CommunicationHistory = ({
  canManagePins,
  canEditNote,
  dealId,
}: CommunicationHistoryProps) => {
  const { notes } = useContext(NotesContext);
  const user = useUser();

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showNewNoteWidget, setShowNewNoteWidget] = useState(false);
  const [selectedNoteType, setSelectedNoteType] = useState(filterOptions[0]);
  const [sortingDirection, setSortingDirection] = useState(sortOptions[0]);

  const [createNote] = useNoteCreateMutation();
  const { notesRefetch } = useContext(NotesContext);
  const onSubmit = async (note: Note, { resetForm, setSubmitting }: FormikHelpers<Note>) => {
    try {
      await createNote({
        variables: {
          note: {
            ...note,
            deal_id: dealId,
            creation_date_tz: getTimezoneStr(),
            note_type: NoteTypeEnum.Manual,
          },
        },
      });
      toast.success('Note added');
      notesRefetch();
    } finally {
      setSubmitting(false);
      resetForm();
    }
  };

  const [{ title, clickText, handleClick, type, noteText, loading }, setModalDetails] =
    useState<ModalDetails>({
      title: '',
      clickText: '',
      handleClick: () => undefined,
      type: '',
      noteText: '',
      loading: false,
    });

  const orderedNotes = useMemo(
    () =>
      notes.filter(GroupedNoteTypeFilter[selectedNoteType.value]).sort((a, b) => {
        if (a.pinned && !b.pinned) {
          return -1;
        }

        return sortingDirection.value === 'desc'
          ? a.created_at! > b.created_at!
            ? -1
            : 1
          : a.created_at! > b.created_at!
          ? 1
          : -1;
      }),
    [notes, selectedNoteType, sortingDirection],
  );

  return (
    <>
      <Box as="section" bgColor="white" borderRadius="10px 10px 0 0">
        <Box as="header" backgroundColor="oxfordBlue" borderRadius="10px 10px 0 0">
          <Box alignItems="center" display="flex" justifyContent="space-between" px={4} py={2}>
            <Heading as="h3" color="white" fontSize="20px" fontWeight="medium">
              Communication History
            </Heading>
            <IconButton
              icon={<MdAddCircle />}
              variant="icon"
              fontSize={32}
              aria-label="Upload Media"
              onClick={() => setShowNewNoteWidget(true)}
            />
          </Box>
          <Flex bgColor="queenBlue" justifyContent="space-between" p="0.75rem" flexWrap="wrap">
            <Box>
              <FormLabel mb={0} color="azureishWhite" fontWeight="normal">
                SORT BY
              </FormLabel>
              <Select
                components={{
                  DropdownIndicator: () => (
                    <Box color="primary" mx={2}>
                      <GoTriangleDown size={20} />
                    </Box>
                  ),
                }}
                onChange={(val) => setSortingDirection(val!)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                options={sortOptions as any}
                styles={{
                  control: (base) => ({
                    ...base,
                    minWidth: '200px',
                  }),
                  indicatorSeparator: (base) => ({
                    ...base,
                    backgroundColor: 'white',
                  }),
                }}
                value={sortingDirection}
              />
            </Box>
            <Box>
              <FormLabel mb={0} color="azureishWhite" fontWeight="normal">
                FILTER
              </FormLabel>
              <Select
                components={{
                  DropdownIndicator: () => (
                    <Box color="primary" mx={2}>
                      <GoTriangleDown size={20} />
                    </Box>
                  ),
                }}
                onChange={(val) => setSelectedNoteType(val!)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                options={filterOptions as any}
                styles={{
                  control: (base) => ({
                    ...base,
                    minWidth: '200px',
                  }),
                  indicatorSeparator: (base) => ({
                    ...base,
                    backgroundColor: 'white',
                  }),
                }}
                value={selectedNoteType}
              />
            </Box>
          </Flex>
        </Box>
        <Box borderColor="cadetBlue" borderWidth="1px" h="500px" overflowY="scroll">
          <Table>
            <Tbody>
              {orderedNotes.map((note: Note) => {
                const isNoteAuthor = note.author_id === user.id;
                const isNoteManual = note.note_type === NoteTypeEnum.Manual;

                if (!note.id) {
                  return null;
                }

                return (
                  <Tr
                    key={note.id}
                    bgColor={note.pinned ? 'yellow.100' : ''}
                    borderBottomColor="gray.100"
                    borderBottomWidth="2px"
                  >
                    <Td
                      fontWeight="normal"
                      p={2}
                      valign="top"
                      verticalAlign="middle"
                      w="min"
                      whiteSpace={{ base: 'normal', md: 'nowrap' }}
                    >
                      {formatDateWithTime(note.created_at, note.creation_date_tz)}
                    </Td>
                    <Td p={2} pl={4} valign="top" verticalAlign="middle" whiteSpace="pre-wrap">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(note.text, {
                            ALLOWED_ATTR: ['href', 'target'],
                          }),
                        }}
                      />
                    </Td>
                    <Td p={2} textAlign="right" verticalAlign="middle" w="min">
                      {note.author?.name}
                    </Td>
                    <Actions
                      isNoteAuthor={isNoteAuthor}
                      setModalDetails={setModalDetails}
                      setShowModal={setShowUpdateModal}
                      id={note.id}
                      noteText={note.text}
                      pinned={note.pinned ?? false}
                      canManagePins={canManagePins}
                      canEditNote={canEditNote}
                      isNoteManual={isNoteManual}
                    />
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <UpdateNoteModal
          setShowModal={setShowUpdateModal}
          showModal={showUpdateModal}
          title={title}
          loading={loading}
          handleClick={handleClick}
          clickText={clickText}
          type={type}
          noteText={noteText}
        />
        {!showNewNoteWidget && (
          <NewNote
            setShowWidget={setShowNewNoteWidget}
            onSubmit={onSubmit}
            borderRadius="0 0 10px 10px"
          />
        )}
      </Box>
      {showNewNoteWidget && (
        <NewNoteWidget setShowWidget={setShowNewNoteWidget} onSubmit={onSubmit} />
      )}
    </>
  );
};

export default CommunicationHistory;
