/* eslint-disable no-continue */

/* eslint-disable no-restricted-syntax */
import { useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { useQuery, useSubscription } from '@apollo/client';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import Big from 'big.js';
import { startOfToday } from 'date-fns/esm';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum, onDealUpdate, titlingDashboardDealsQuery } from '../gql/dealGql';
import {
  FollowUpStatusEnum,
  Pod as GeneratedPod,
  Maybe,
  useDashboardDealsQuery,
  useLaneKeyMetricsQuery,
} from '../gql/generated/graphql';
import { LaneKeyMetrics } from '../gql/laneKeyMetrics';
import { Pod } from '../gql/podGql';

import LeaseEndContainer from '../components/Container/LEContainer';
import BottomSection from '../components/Dashboards/BottomSection';
import ButtonsRow from '../components/Dashboards/ButtonsRow';
import DealsSwimlane from '../components/DealsSwimlane/DealsSwimlane';
import PayoffDealsTable from '../components/PayoffDealsTable';
import TableView from '../components/TableView';
import {
  SortingActionKind,
  FiltersActionKind as TitlingFiltersActionKind,
  initialSortingState,
  sortingReducer,
} from '../components/TableView/utils';
import { PermissionEnum } from '../constants/permissions';
import { FiltersActionKind as GlobalFiltersActionKind } from '../globalFiltersUtils';
import { PaginationContext } from '../hooks/usePagination';
import { useUserAbilityAndVisibleStates } from '../hooks/useUserAbilityAndVisibleStates';
import { FiltersContext } from '../libs/contextLib';
import { User } from '../types/user';
import { Swimlane, allSwimlanes, groupDealsByState } from '../utils/dashboard';
import { filterOwner, isAllSources, isAllTypes } from '../utils/permissions';

type MergeDealArgs = {
  originalDeals: Deal[];
  updatedDeals: Deal[];
  user: User;
  userPods: Maybe<GeneratedPod>[];
  userPermissions: Set<PermissionEnum>;
  options: {
    filterByOwner: boolean;
    seeAllPods: boolean;
    includeUnclaimedDeals: boolean;
    showFollowUps: boolean;
  };
};

const mergeDeals = ({
  originalDeals,
  updatedDeals,
  user,
  userPods,
  userPermissions,
  options: { filterByOwner, seeAllPods, includeUnclaimedDeals, showFollowUps },
}: MergeDealArgs) => {
  let filterOriginalDeals = [...originalDeals];
  let filterUpdatedDeals = [...updatedDeals];

  const updatedDealIds: number[] = filterUpdatedDeals.map((ud) => ud.id as number);

  filterOriginalDeals = filterOriginalDeals.filter(
    (od) => !updatedDealIds.includes(od.id as number),
  );

  if (filterByOwner) {
    filterUpdatedDeals = filterOwner(filterUpdatedDeals, user, userPermissions, {
      includeUnclaimedDeals,
    });
  }

  // Filter for when onDealUpdate was for followUp or pod/user assignment
  filterUpdatedDeals = filterUpdatedDeals.filter((deal) => {
    const dealHasSalesPodAssigned = !!deal.pod?.id;
    const userBelongsToAssignedSalesPod = userPods.some(
      (userPod) => userPod?.id && userPod.id === deal.pod?.id,
    );
    const dealHasDueFollowUp = deal.follow_up?.status === FollowUpStatusEnum.Due;
    const dealDoesNotHaveFollowUp = !deal.follow_up;

    return (
      (seeAllPods || !dealHasSalesPodAssigned || userBelongsToAssignedSalesPod) &&
      (showFollowUps || dealDoesNotHaveFollowUp || dealHasDueFollowUp)
    );
  });

  // Return all relevant deals
  return filterUpdatedDeals.concat(filterOriginalDeals);
};

export enum CurrentView {
  DashboardView = 'DashboardView',
  TitleTableView = 'TitleTableView',
  PayoffRequestsView = 'PayoffRequestsView',
}

const Dashboard = () => {
  const {
    filters: {
      titling: titlingFilters,
      global: {
        selectedPodId,
        sources,
        types,
        currentView,
        showOnlyUnread,
        showAssignedToMe,
        showFollowUps,
        showBootRequested,
        showAssignedToDeletedUsers,
        showActiveTags,
        showMatureFollowUps,
      },
    },
    dispatch: dispatchFilters,
  } = useContext(FiltersContext);

  const {
    viewAsUser,
    viewAsUserAbility,
    viewAsUserStates,
    viewAsUserPods,
    loggedUser,
    loggedUserAbility,
    loggedUserStates,
    loading: loadingViewAsUserAbilityAndVisibleStates,
    viewingAsSelf,
  } = useUserAbilityAndVisibleStates();

  const [sorting, dispatchSorting] = useReducer(sortingReducer, initialSortingState);

  const paginationContext = useContext(PaginationContext);

  const [isLargerThan2008] = useMediaQuery('(min-width: 2008px)');

  useEffect(() => {
    if (viewAsUser.id !== loggedUser.id) {
      dispatchFilters({
        type: GlobalFiltersActionKind.SET_CURRENT_VIEW,
        payload: CurrentView.DashboardView,
      });
    }
  }, [viewAsUser.id, loggedUser.id]);

  useEffect(() => {
    dispatchFilters({
      type: TitlingFiltersActionKind.SET_NOTIFICATIONS,
      payload: showOnlyUnread,
    });
  }, [showOnlyUnread]);

  useEffect(() => {
    if (titlingFilters.field === sorting.field && titlingFilters.direction === sorting.direction) {
      return;
    }

    paginationContext.jumpToPage(titlingFilters.currentPage);
    paginationContext.setItemsPerPage(titlingFilters.itemsPerPage);
    dispatchSorting({
      type: SortingActionKind.SET_FIELD,
      payload: titlingFilters.field,
    });
    dispatchSorting({
      type: SortingActionKind.SET_DIRECTION,
      payload: titlingFilters.direction,
    });
  }, [
    titlingFilters.field,
    titlingFilters.direction,
    titlingFilters.currentPage,
    titlingFilters.itemsPerPage,
    sorting.field,
    sorting.direction,
  ]);

  const { data: dashboardDealsData, loading: dashboardDealsLoading } = useDashboardDealsQuery({
    variables: {
      assigned_to_me_filter: showAssignedToMe,
      boot_requested_filter: showBootRequested,
      assigned_to_deleted_users_filter: showAssignedToDeletedUsers,
      unread_messages_filter: showOnlyUnread,
      // If we have all sources, we don't need to pass them to the query, no need to filter by source
      sources: isAllSources(sources) ? [] : sources,
      types: isAllTypes(types) ? [] : types,
      states: viewAsUserStates,
      showFollowUps,
      pod_id: selectedPodId,
      // Only pass these values if you aren't viewing as yourself, otherwise they'll be on the token already
      ...(!viewingAsSelf && {
        user_id: viewAsUser?.id,
        permissions: Array.from(viewAsUserAbility),
      }),
      showActiveTags,
      showMatureFollowUps,
    },
    fetchPolicy: 'no-cache', // TODO: temporary solution. We should remove this when we have a better solution
    skip:
      currentView !== CurrentView.DashboardView ||
      !viewAsUserStates.length ||
      loadingViewAsUserAbilityAndVisibleStates,
    onError: (error) => {
      toast.error(error.message);
    },
  });

  // It would be nice to remove this `useState` but it is a mess
  // to handle the `onDealUpdate` subscription without it
  const [swimlanes, setSwimlanes] = useState<Swimlane>({} as Swimlane);
  useEffect(() => {
    if (!dashboardDealsData?.dashboardDeals) {
      setSwimlanes({} as Swimlane);
    }
    // TODO: temporary conversion. We should remove all classes and use the generated classes
    setSwimlanes(groupDealsByState((dashboardDealsData?.dashboardDeals || []) as Deal[]));
  }, [dashboardDealsData?.dashboardDeals]);

  const { data: onDealUpdateData } = useSubscription(onDealUpdate, {
    variables: { sources, types },
  });

  useEffect(() => {
    if (!onDealUpdateData?.onDealUpdate) {
      return;
    }

    // Merges updated deals with the original deals after applying filters
    const mergedDeals = mergeDeals({
      originalDeals: Object.keys(swimlanes).flatMap((key) => swimlanes[key as DealStateEnum]),
      updatedDeals: onDealUpdateData.onDealUpdate,
      user: viewAsUser,
      userPods: selectedPodId ? [new Pod(selectedPodId) as GeneratedPod] : viewAsUserPods,
      userPermissions: viewAsUserAbility,
      options: {
        filterByOwner: viewAsUserAbility.has(PermissionEnum.FilterDealOwner),
        seeAllPods: viewAsUserAbility.has(PermissionEnum.SeeAllPods),
        includeUnclaimedDeals: true,
        showFollowUps,
      },
    });

    // Add updated deals
    const updatedSwimlanes = groupDealsByState(mergedDeals);

    setSwimlanes(updatedSwimlanes);
  }, [onDealUpdateData?.onDealUpdate]);

  const { data: laneKeyMetricsData } = useLaneKeyMetricsQuery({
    variables: {
      today: startOfToday(),
      sources,
      types,
    },
    fetchPolicy: 'cache-and-network',
    skip: currentView !== CurrentView.DashboardView,
  });

  const laneKeyMetrics = (laneKeyMetricsData?.laneKeyMetrics ?? []) as LaneKeyMetrics[];

  const { currentPage, itemsPerPage, field, direction, ...filters } = titlingFilters;

  // only used to keep 'Team' and 'Assigned to' filters when changing page
  delete filters.userChangedFilters;
  const titlingDashboardDeals = useQuery(titlingDashboardDealsQuery, {
    variables: {
      sources,
      types,
      assigned_to_deleted_users_filter: showAssignedToDeletedUsers,
      states: loggedUserStates,
      pod_id: selectedPodId,
      page: currentPage,
      itemsPerPage,
      filters,
      sorting: { field, direction },
    },
    skip: currentView !== CurrentView.TitleTableView || !loggedUserStates.length,
    fetchPolicy: 'cache-and-network',
  });

  const dealsMaxAge = titlingDashboardDeals?.data?.titlingDashboardDeals?.dealsMaxAge ?? undefined;
  let titlingDeals = useMemo(() => {
    if (!titlingDashboardDeals?.data?.titlingDashboardDeals) {
      return [];
    }

    const { totalRecords, deals } = titlingDashboardDeals.data.titlingDashboardDeals;
    paginationContext.setTotalRecords(totalRecords ?? 0);
    return (deals ?? []) as Deal[];
  }, [titlingDashboardDeals.data?.titlingDashboardDeals]);

  titlingDeals = useMemo(() => {
    if (!onDealUpdateData?.onDealUpdate || currentView !== CurrentView.TitleTableView) {
      return titlingDeals;
    }

    const updatedTitlingDeals = [...titlingDeals];
    for (const updatedDeal of onDealUpdateData.onDealUpdate) {
      const dealIndex = updatedTitlingDeals.findIndex(
        (titlingDeal) => titlingDeal.id === updatedDeal.id,
      );

      if (dealIndex !== -1) {
        updatedTitlingDeals[dealIndex] = updatedDeal;
      }
    }
    return updatedTitlingDeals;
  }, [titlingDeals, currentView, onDealUpdateData?.onDealUpdate]);

  const visibleSwimlanes = useMemo(() => {
    const res = [];
    for (const sl of allSwimlanes) {
      if (!viewAsUserAbility.has((PermissionEnum.See + sl.title) as PermissionEnum)) {
        continue;
      }

      let allDeals: Deal[] = [];
      for (const state of sl.states) {
        allDeals = allDeals.concat(swimlanes[state] || []);
      }

      const dailyCount = laneKeyMetrics.reduce((count, metric) => {
        if (!sl.states.includes(metric.state)) {
          return count;
        }
        return count.plus(metric.day_count ?? 0);
      }, new Big(0));

      res.push({
        label: sl.label,
        title: sl.title,
        deals: allDeals,
        dailyCount: dailyCount.toNumber(),
        assumedId: viewAsUser.id,
      });
    }
    return res;
  }, [viewAsUserAbility, viewAsUser, swimlanes, laneKeyMetrics]);

  return (
    <Flex flexDirection="column">
      <ButtonsRow />
      <LeaseEndContainer
        isLoading={dashboardDealsLoading || loadingViewAsUserAbilityAndVisibleStates}
      >
        <Box bgColor="snowyWhite">
          {(loggedUserAbility.has(PermissionEnum.TitleClerk) ||
            loggedUserAbility.has(PermissionEnum.SuperUser)) &&
          currentView === CurrentView.TitleTableView ? (
            <Flex direction="column" alignItems="center" gap={4}>
              <TableView
                deals={titlingDeals}
                maxAge={dealsMaxAge}
                loading={titlingDashboardDeals.loading}
                sorting={sorting}
                dispatchSorting={dispatchSorting}
              />
              <BottomSection minW="70%" />
            </Flex>
          ) : loggedUserAbility.has(PermissionEnum.SeeAllPayoffRequests) &&
            currentView === CurrentView.PayoffRequestsView ? (
            <PayoffDealsTable />
          ) : (
            <Flex flexWrap="wrap" justifyContent="center" gap={4}>
              <Flex w={isLargerThan2008 ? '65%' : '100%'} gap={2} overflow="auto">
                {visibleSwimlanes.map((sl) => (
                  <DealsSwimlane
                    key={sl.title}
                    deals={sl.deals}
                    title={sl.label || sl.title}
                    dailyCount={sl.dailyCount}
                    assumedId={viewAsUser.id}
                  />
                ))}
              </Flex>
              <BottomSection />
            </Flex>
          )}
        </Box>
      </LeaseEndContainer>
    </Flex>
  );
};

export default Dashboard;
