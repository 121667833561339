import { useEffect, useState } from 'react';

import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import { Deal, DealStateEnum } from '../../../../gql/dealGql';
import { ProcessorEnum } from '../../../../gql/financialInfoGql';
import { useUpdateProcessorMutation } from '../../../../gql/generated/graphql';

import CustomReactSelect from '../../../shared/CustomReactSelect';

import { logger } from '../../../../libs/Logger';
import { ProcessorOption, processorOptions } from '../../../../utils/deals';

const getOption = (processor: ProcessorEnum | undefined) =>
  processor ? { value: processor, label: processor } : null;

const validate = (state: DealStateEnum, value?: string) => {
  if (state === DealStateEnum.Finalized) {
    return Object.values(ProcessorEnum).includes(value as ProcessorEnum);
  }
  return Object.values(ProcessorEnum).includes(value as ProcessorEnum) || value == null;
};

type ProcessorAutoCompleteProps = {
  deal: Deal;
};

const ProcessorAutoComplete = ({ deal }: ProcessorAutoCompleteProps) => {
  const [selectedOption, setSelectedOption] = useState<ProcessorOption | null>(
    getOption(deal?.financial_info?.processor),
  );
  const [updateProcessor, { loading }] = useUpdateProcessorMutation();

  useEffect(() => {
    if (deal?.financial_info?.processor) {
      setSelectedOption(getOption(deal.financial_info.processor));
    }
  }, [deal?.financial_info?.processor]);

  const handleChange = async (selected: SingleValue<ProcessorOption>) => {
    const isValid = validate(deal.state, selected?.value);
    if (deal?.id && deal.financial_info?.id && isValid) {
      await updateProcessor({
        variables: {
          deal_id: deal.id,
          fi_id: deal.financial_info.id,
          processor: selected?.value || null,
        },
      })
        .then(() => {
          setSelectedOption(selected);
          toast.success('Processor updated');
        })
        .catch((error) => {
          logger.error('ProcessorAutoComplete.tsx', '', null, error);
          toast.error(error?.message || 'Falied to update processor');
        });
    }
  };

  return (
    <CustomReactSelect
      placeholder=""
      isClearable={deal.state !== DealStateEnum.Finalized}
      options={processorOptions}
      onChange={handleChange}
      value={selectedOption}
      isLoading={loading}
    />
  );
};

export default ProcessorAutoComplete;
