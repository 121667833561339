import { useContext, useMemo } from 'react';

import { Maybe, useRequiredDocumentsAndValidationsQuery } from '../gql/generated/graphql';
import { TTDocumentName, TTValidationName } from '../gql/taterTitleGql';

import { DealContext } from '../libs/DealContext';
import { mapTTDocumentType } from '../utils/media';

import useRequiredDocsVariables from './useRequiredDocsVariables';

const getMinDaysBeforeRegistrationExpires = (
  registrationValidations: TTValidationName[],
): number | null => {
  if (registrationValidations.includes(TTValidationName.RegistrationNotExpired90Days)) {
    return 90;
  }
  if (registrationValidations.includes(TTValidationName.RegistrationNotExpired60Days)) {
    return 60;
  }
  if (registrationValidations.includes(TTValidationName.RegistrationNotExpired)) {
    return 0;
  }
  return null;
};

interface UseRequiredDocumentsAndValidationsProps {
  shouldExecute: boolean;
}

export const useRequiredDocumentsAndValidations = ({
  shouldExecute,
}: UseRequiredDocumentsAndValidationsProps) => {
  const {
    deal,
    documentMedia: { mediaList },
  } = useContext(DealContext);

  const isMissingRequiredVariables =
    !deal.customer?.address?.state || !deal.financial_info || !deal.car;

  const { requiredDocsVariables } = useRequiredDocsVariables(deal, mediaList);

  const { loading, data } = useRequiredDocumentsAndValidationsQuery({
    skip: !shouldExecute || isMissingRequiredVariables,
    fetchPolicy: 'cache-and-network',
    variables: requiredDocsVariables,
  });

  const rawData = data ?? {};
  const { requiredDocuments, validations, registrationValidations } = useMemo(
    () =>
      (rawData.jurisdiction?.products?.items ?? []).reduce(
        (acc, productItem) => {
          const productItemDocumentNames =
            productItem?.documents?.items
              ?.map((documentItem) =>
                mapTTDocumentType(documentItem?.type?.name as Maybe<TTDocumentName>),
              )
              .filter(Boolean) ?? [];

          // `License` and `Insurance` validations come at Product level
          const productItemValidationNames =
            productItem?.validations
              ?.map((validation) => validation?.name as TTValidationName)
              .filter(Boolean) ?? [];
          // `Registration` validations come at Document level
          const registrationValidationNames =
            productItem?.documents?.items
              ?.find((documentItem) => documentItem?.type?.name === TTDocumentName.Registration)
              ?.validations?.map((validation) => validation?.name as TTValidationName) ?? [];

          return {
            requiredDocuments: [...acc.requiredDocuments, ...productItemDocumentNames],
            validations: [...acc.validations, ...productItemValidationNames],
            registrationValidations: [
              ...acc.registrationValidations,
              ...registrationValidationNames,
            ],
          };
        },
        {
          requiredDocuments: [] as string[],
          validations: [] as TTValidationName[],
          registrationValidations: [] as TTValidationName[],
        },
      ),
    [rawData],
  );
  const minDaysBeforeRegistrationExpires = useMemo(
    () => getMinDaysBeforeRegistrationExpires(registrationValidations),
    [registrationValidations],
  );

  return {
    loading,
    rawData,
    requiredDocuments,
    validations,
    registrationValidations,
    minDaysBeforeRegistrationExpires,
  };
};
