import { DealStateEnum } from '../gql/dealGql';

// Keep in sync with API: types/dealDates.ts
export type Dates = {
  [key in DealStateEnum]?: string;
};
export type CustomDates = {
  [key in DealStateEnum | 'wet_sig_sent_date' | 'wet_sig_received_date']?: string;
};

export class DealDates {
  id?: number;
  deal_id?: number;
  dates?: Dates | null;
  custom_dates?: CustomDates | null;

  constructor() {
    this.id = 0;
    this.deal_id = 0;
    this.dates = {};
    this.custom_dates = {};
  }
}
