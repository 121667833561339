import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { DeepPartial } from '@chakra-ui/react';
import { merge } from 'lodash';
import { useParams } from 'react-router-dom';

import { Deal, dealQuery, dealSeen } from '../gql/dealGql';
import {
  Maybe,
  PayoffRequest,
  PayoffRequestQuery,
  TtJurisdiction,
  usePayoffRequestQuery,
} from '../gql/generated/graphql';
import { activePayoffRequestStatuses } from '../gql/payoffRequestGql';

import useCanAutoStructure from '../hooks/useCanAutoStructure';
import useDocumentMedia, { DocumentMedia } from '../hooks/useDocumentMedia';
import { useUser } from '../hooks/useUser';
import { isClaimRequired } from '../utils/permissions';

import { AbilityContext, FiltersContext } from './contextLib';

interface DealContextType {
  deal: Deal;
  dispatch: Dispatch<DealActions>;
  dealRefetch: () => Promise<ApolloQueryResult<Deal>>;
  isLoading: boolean;
  documentMedia: DocumentMedia;
  jurisdiction: TtJurisdiction;
  isRecalculatingPayoff: boolean;
  setIsRecalculatingPayoff: Dispatch<SetStateAction<boolean>>;
  canAutoStructure: boolean;
  everyoneIsPrequalified: boolean;
  setEveryoneIsPrequalified: Dispatch<SetStateAction<boolean>>;
  autosaving: boolean;
  setAutosaving: Dispatch<SetStateAction<boolean>>;
  userChangedSomething: boolean;
  setUserChangedSomething: Dispatch<SetStateAction<boolean>>;
  payoffRequest: Maybe<PayoffRequest>;
  isPayoffRequested: boolean;
  payoffRequestRefetch: () => Promise<ApolloQueryResult<PayoffRequestQuery>>;
  claimIsRequired: boolean;
}

export const DealContext = createContext<DealContextType>({
  deal: new Deal(),
  dispatch: () => Deal,
  dealRefetch: () => Promise.resolve({} as ApolloQueryResult<Deal>),
  isLoading: false,
  documentMedia: {
    mediaList: [],
    internalMediaList: [],
    customerMediaList: [],
    unverifiedRequiredMediaList: [],
    missingRequiredMediaList: [],
    signatureFileList: [],
  },
  jurisdiction: {},
  isRecalculatingPayoff: false,
  setIsRecalculatingPayoff: () => null,
  canAutoStructure: false,
  everyoneIsPrequalified: false,
  setEveryoneIsPrequalified: () => null,
  autosaving: false,
  setAutosaving: () => null,
  userChangedSomething: false,
  setUserChangedSomething: () => null,
  payoffRequest: null,
  isPayoffRequested: false,
  payoffRequestRefetch: () => Promise.resolve({} as ApolloQueryResult<PayoffRequestQuery>),
  claimIsRequired: true,
});

export enum DealActionsEnum {
  SetDeal = 'setDeal',
  UpdateDeal = 'updateDeal',
  DeepUpdateDeal = 'deepUpdateDeal',
}

interface SetDealAction {
  type: DealActionsEnum.SetDeal;
  payload: Deal;
}

interface UpdateDealAction {
  type: DealActionsEnum.UpdateDeal;
  payload: Partial<Deal>;
}

interface DeepUpdateDealAction {
  type: DealActionsEnum.DeepUpdateDeal;
  payload: DeepPartial<Deal>;
}

export type DealActions = SetDealAction | UpdateDealAction | DeepUpdateDealAction;

type ReducerType = (deal: Deal, action: DealActions) => Deal;

const reducer: ReducerType = (deal, action) => {
  switch (action.type) {
    case DealActionsEnum.SetDeal:
      return action.payload;
    case DealActionsEnum.UpdateDeal:
      return { ...deal, ...action.payload };
    // Allows to update specific props of nested objects
    case DealActionsEnum.DeepUpdateDeal:
      return merge({}, deal, action.payload);
    default:
      throw new Error();
  }
};

interface DealProviderProps {
  children: ReactNode;
}

export const DealProvider = ({ children }: DealProviderProps) => {
  const user = useUser();
  const abilities = useContext(AbilityContext);
  const [deal, dispatch] = useReducer<ReducerType, Deal>(
    reducer,
    new Deal(),
    (initialState) => initialState,
  );
  const {
    filters: {
      global: { sources, types },
    },
  } = useContext(FiltersContext);
  const { id } = useParams<{ id: string }>();

  const [isRecalculatingPayoff, setIsRecalculatingPayoff] = useState<boolean>(false);
  const [everyoneIsPrequalified, setEveryoneIsPrequalified] = useState<boolean>(false);
  const [autosaving, setAutosaving] = useState<boolean>(false);
  const [userChangedSomething, setUserChangedSomething] = useState<boolean>(false);

  const claimIsRequired = useMemo(
    () => isClaimRequired(deal, abilities, user),
    [deal, abilities, user],
  );

  const [markDealSeen] = useMutation(dealSeen);
  const dealGql = useQuery(dealQuery, {
    variables: {
      id,
      sources,
      types,
    },
    // Fragments return empty objects when using `network-only`.
    fetchPolicy: 'no-cache',
    // Added `notifyOnNetworkStatusChange` because `onCompleted` is not called when refetching: https://github.com/apollographql/apollo-client/issues/11306
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data?.deal) {
        return;
      }

      const newDeal = data.deal as Deal;
      dispatch({ type: DealActionsEnum.SetDeal, payload: newDeal });

      markDealSeen({
        variables: { dealId: newDeal.id },
      });
    },
  });

  const { documentMedia, jurisdiction } = useDocumentMedia({
    deal,
    dealDispatch: dispatch,
  });

  const { canAutoStructure } = useCanAutoStructure(deal);

  const { data: payoffRequestData, refetch: payoffRequestRefetch } = usePayoffRequestQuery({
    variables: { deal_id: deal.id },
    skip: !deal.id,
    fetchPolicy: 'cache-and-network',
  });

  const payoffRequest = payoffRequestData?.payoffRequest ?? null;
  const isPayoffRequested = payoffRequestData?.payoffRequest?.status
    ? activePayoffRequestStatuses.includes(payoffRequestData?.payoffRequest?.status)
    : false;

  return (
    <DealContext.Provider
      value={{
        deal,
        dispatch,
        documentMedia,
        jurisdiction,
        dealRefetch: dealGql.refetch,
        isLoading: dealGql.loading,
        isRecalculatingPayoff,
        setIsRecalculatingPayoff,
        canAutoStructure,
        everyoneIsPrequalified,
        setEveryoneIsPrequalified,
        autosaving,
        setAutosaving,
        userChangedSomething,
        setUserChangedSomething,
        payoffRequest,
        isPayoffRequested,
        payoffRequestRefetch,
        claimIsRequired,
      }}
    >
      {children}
    </DealContext.Provider>
  );
};
