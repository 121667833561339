import { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, HStack, Table, Tbody, Text, Th, Thead } from '@chakra-ui/react';

import { Auction, auctionsQuery } from '../../gql/auctionGql';
import { DealType } from '../../gql/generated/graphql';

import ExportDealsToXlsxButton from '../ExportDealsToXlsxButton/ExportDealsToXlsxButton';
import Loader from '../Loader';

import CompletedDealsRow from './CompletedDealsRow';

import { CompletedDealsProps, useCompletedDeals } from '../../hooks/useCompletedDeals';
import { FiltersContext } from '../../libs/contextLib';
import {
  CompletedDealColumn,
  completedAcquisitionDealColumns,
} from '../../pages/CompletedDealsPage/constants';

const CompletedAcquisitionDealsTable = ({
  startDate,
  endDate,
  user,
  dealState,
  addressState,
  notifications,
}: CompletedDealsProps) => {
  const {
    filters: {
      global: {
        showAssignedToMe,
        showBootRequested,
        showAssignedToDeletedUsers,
        showActiveTags,
        showMatureFollowUps,
      },
    },
  } = useContext(FiltersContext);

  const [auctions, setAuctions] = useState<Auction[]>([]);

  const auctionsGql = useQuery(auctionsQuery);

  useEffect(() => {
    if (auctionsGql.data && auctionsGql.data.auctions) {
      setAuctions(auctionsGql.data.auctions);
    }
  }, [auctionsGql.data]);

  const { query, completedDeals } = useCompletedDeals({
    assignedToMeFilter: showAssignedToMe,
    bootRequestedFilter: showBootRequested,
    assignedToDeletedUsersFilter: showAssignedToDeletedUsers,
    types: [DealType.Acquisition],
    startDate,
    endDate,
    user,
    dealState,
    addressState,
    notifications,
    showActiveTags,
    showMatureFollowUps,
  });

  return (
    <Box>
      <HStack p={3} bgColor="oxfordBlue" borderTopRadius={8} spacing="auto">
        <Text fontSize={20} textColor="white">
          Completed Acquisition Deals
        </Text>
        <ExportDealsToXlsxButton
          aria-label="Export Completed Acquisitions"
          auctions={auctions}
          columns={completedAcquisitionDealColumns}
          deals={completedDeals}
        />
      </HStack>
      <Box overflowX="scroll" overflowY="auto">
        <Table>
          <Thead bgColor="queenBlue" px={6} height="50px">
            {completedAcquisitionDealColumns.map(({ header, maxW, minW }: CompletedDealColumn) => (
              <Th
                key={header}
                textColor="white"
                textAlign="center"
                p={0}
                maxW={maxW ?? '100%'}
                minW={minW ?? '95px'}
              >
                {header}
              </Th>
            ))}
          </Thead>
          <Tbody>
            {completedDeals.map((d) => (
              <CompletedDealsRow deal={d} />
            ))}
          </Tbody>
        </Table>
        <Loader isLoading={query.loading} />
      </Box>
    </Box>
  );
};

export default CompletedAcquisitionDealsTable;
