import React, { useEffect, useState } from 'react';

import {
  Button,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { DealStateEnum } from '../../../../gql/dealGql';
import { useCustomDatesUpdateMutation } from '../../../../gql/generated/graphql';

import colors from '../../../../chakra/foundations/colors';
import { formatDateISO, getUTCDate } from '../../../../libs/utils';
import { getTitleReceivedDateValidation } from '../../../../libs/yup-validators/dates';
import { Dates } from '../../../../types/dealDates';

type TitleReceivedDateInputProps = {
  dealId?: number;
  value?: string;
  dates?: Dates | null;
} & ChakraInputProps;

const TitleReceivedDateInput: React.FC<TitleReceivedDateInputProps> = ({
  dealId,
  value,
  dates,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [previousDate, setPreviousDate] = useState<string>('');
  const [modified, setModified] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>('');

  const [updateCustomDates] = useCustomDatesUpdateMutation();

  useEffect(() => {
    const formattedDate = value ? formatDateISO(new Date(value)) : '';
    setPreviousDate(formattedDate);
    setSelectedDate(formattedDate);
  }, [value]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError('');
    const newSelectedDate = event.target.value ?? '';
    setSelectedDate(newSelectedDate);
    setModified(newSelectedDate !== previousDate);
  };

  const handleSave = async () => {
    try {
      getTitleReceivedDateValidation(dates).validateSync(selectedDate);
    } catch (error: unknown) {
      const { message } = error as Yup.ValidationError;
      setValidationError(message);

      return;
    }

    if (!dealId) {
      return;
    }

    try {
      await updateCustomDates({
        variables: {
          deal_id: dealId,
          date_key: DealStateEnum.TitleReceived,
          date: selectedDate ? getUTCDate(selectedDate) : null,
        },
      });

      setPreviousDate(selectedDate);
      setModified(false);
      toast.success('Title Received Date updated');
    } catch (error) {
      toast.error('Failed to update Title Received Date');
    }
  };

  const handleCancel = () => {
    setSelectedDate(previousDate);
    setValidationError('');
    setModified(false);
  };

  return (
    <Flex direction="row">
      <Tooltip label={validationError} bgColor="errorsRed">
        <ChakraInput
          focusBorderColor={validationError ? 'errorsRed' : undefined}
          type="date"
          onChange={handleChange}
          value={selectedDate}
          isInvalid={!!validationError}
          {...rest}
        />
      </Tooltip>
      {modified ? (
        <>
          <Tooltip label="Cancel" bgColor="errorsRed">
            <Button size="sm" variant="link" className="ml-2" onClick={handleCancel}>
              <BiXCircle color={colors.errorsRed} />
            </Button>
          </Tooltip>
          <Tooltip label="Save" bgColor="green">
            <Button size="sm" variant="link" onClick={handleSave}>
              <BiCheckCircle color={colors.green} />
            </Button>
          </Tooltip>
        </>
      ) : null}
    </Flex>
  );
};

export default TitleReceivedDateInput;
